<template>
  <div class="home-wrapper">
    <div class="binner">
      <div class="wp">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="(item, ind) in imgList" :key="ind">
            <img :src="item.logoUrl" alt="" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="wp">
      <div class="cont">
        <div class="left" @click="goDetail(list[0])">
          <img :src="list[0].imageUrl" alt="" />
          <h3 style="color: #333; border: none">
            {{ list[0].title.substring(0, 24) + '...' }}
          </h3>
        </div>
        <div class="right">
          <h3>
            <span>新闻动态资讯</span>
            <a
              @click="
                $router.push({
                  path: '/yjyNews',
                })
              "
              >···</a
            >
          </h3>
          <ul>
            <li v-for="item in list" :key="item.id" @click="goDetail(item)">
              <div class="title">
                <a>{{ item.title.substring(0, 24) + '...' }}</a>
              </div>
              <div class="time">{{ item.createTime.substring(0, 10) }}</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="cont" style="margin-bottom: 100px">
        <div class="left">
          <h3>
            <span>工信厅政策</span>
            <a
              @click="
                $router.push({
                  path: '/yjyExample',
                })
              "
              >···</a
            >
          </h3>
          <ul>
            <li v-for="item in list1" :key="item.id" @click="goDetail1(item)">
              <div class="title">
                <a>{{ item.title.substring(0, 24) + '...' }}</a>
              </div>
              <div class="time">{{ item.createTime.substring(0, 10) }}</div>
            </li>
          </ul>
        </div>
        <div class="right">
          <h3>友情链接</h3>
          <div class="box">
            <div class="item">
              <a href="http://gxt.jiangxi.gov.cn/" target="_blank">
                <span class="icon">
                  <img src="./images/icon2.png" alt="" />
                </span>
                <span class="name">江西省工业和信息化厅</span>
              </a>
            </div>
            <div class="item">
              <a
                href="https://www.jxgydsj.com/XOffice/login/index/360000"
                target="_blank"
              >
                <span class="icon">
                  <img src="./images/icon1.png" alt="" />
                </span>
                <span class="name">江西省开发区统一数字化管理服务平台</span>
              </a>
            </div>

            <div class="item">
              <a
                href="https://www.jx968969.com/scientific-front/home"
                target="_blank"
              >
                <span class="icon">
                  <img src="./images/icon3.png" alt="" />
                </span>
                <span class="name">江西省青年科技工作者成果转化平台</span>
              </a>
            </div>
            <!-- <div class="item">
              <span class="icon">
                <img src="./images/icon4.png" alt="" />
              </span>
              <span class="name">服务领域</span>
            </div>
            <div class="item">
              <span class="icon">
                <img src="./images/icon5.png" alt="" />
              </span>
              <span class="name">合作单位</span>
            </div> -->
            <div class="item">
              <span class="icon">
                <img src="./images/icon6.png" alt="" />
              </span>
              <span class="name">研究院官方微信公众号</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Http from '@/utils/http';
export default {
  name: 'home-wrapper',
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
        navigation: {
          nextEl: '.carousel-btnright',
          prevEl: '.carousel-btnleft',
        },
      },
      page: 1,
      total: 0,
      list: [
        {
          imageUrl: '',
          title: '',
          createTime: '',
        },
      ],
      list1: [],
      imgList: [],
    };
  },
  mounted() {
    document.title = '江西省云智大数据产业研究院';
    this.getList();
    this.getList1();
    this.getDictList();
  },
  methods: {
    goDetail(item) {
      this.$router.push({
        path: '/yjyNewsDetail',
        query: { id: item.id },
      });
    },
    getList() {
      Http({
        method: 'get',
        url: 'https://rh.2hrh.com/Rhinfo/newsInfo/list',
        data: {
          typeId: 'b2efdab97978477ca97256f6c1a64c64',
          page: this.page,
          limit: 8,
        },
      }).then((res) => {
        // console.log(res);
        this.list = res.data.records;
        this.total = Number(res.data.total);
      });
    },

    goDetail1(item) {
      this.$router.push({
        path: '/yjyExampleDetail',
        query: { id: item.id },
      });
    },
    getDictList() {
      Http({
        method: 'get',
        url: 'https://rh.2hrh.com/Rhinfo/permission/dictionaryInfo/listNoPage',
        data: {
          dicType: '4',
        },
      }).then((res) => {
        console.log(res);
        this.imgList = res.data;
      });
    },
    getList1() {
      Http({
        method: 'get',
        url: 'https://rh.2hrh.com/Rhinfo/newsInfo/list',
        data: {
          typeId: 'b4157bd616a649378eb330bfd4d695b3',
          page: this.page,
          limit: 8,
        },
      }).then((res) => {
        // console.log(res);
        this.list1 = res.data.records;
        this.total = Number(res.data.total);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home-wrapper {
  .binner {
    padding-top: 90px;
    height: 500px;
    background: #008cd6;
    // url(../../../assets/front/about/aboutbanner.png) center center
    //   no-repeat;
    .wp {
      margin: unset;
      max-width: unset;
      .swiper-slide {
        width: 100%;
      }
      img {
        width: 100%;
        height: 500px;
      }
    }
  }
  .cont {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    .left,
    .right {
      width: 46%;
      height: 400px;
      background: #ebf5fe;
      padding: 8px 20px;
      border-radius: 8px;
      img {
        width: 100%;
        height: 90%;
      }
      h3 {
        width: 100%;
        line-height: 32px;
        color: #056bc7;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 8px;
        border-bottom: 1px #d3d9dd solid;
        display: flex;
        justify-content: space-between;
        a {
          color: #056bc7;
          cursor: pointer;
        }
      }
      ul {
        li {
          cursor: pointer;
          width: 100%;
          line-height: 42px;
          float: left;
          display: block;
          font-size: 15px;
          border-bottom: 1px #ddd dotted;
          /* padding-bottom: 10px; */
          color: #333;
          height: 42px;
          .title {
            width: 80%;
            float: left;
          }
          .time {
            font-size: 14px;
            color: #999;
            float: right;
          }
        }
        li:hover {
          color: #008cd6;
        }
      }
    }
    .left {
    }
    .right {
      .box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          display: block;
          width: 49%;
          height: 174px;
          margin-bottom: 8px;
          text-align: center;
          cursor: pointer;
          opacity: 0.6;
          .icon {
            width: 81px;
            height: 81px;
            display: block;
            margin: 15px auto;
            img {
              width: 81px;
              height: 81px;
            }
          }

          .name {
            font-size: 15px;
            color: #333;
            font-weight: bold;
          }
        }
        .item:hover {
          opacity: 1;
          .name {
            color: #056bc7;
          }
        }
      }
    }
  }
}
</style>
